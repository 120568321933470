/* eslint-disable max-classes-per-file */
import { Exclude, Expose, Transform, Type } from 'class-transformer'

import { BookCharacterType } from '@models/book/BookCharacterType'
import { BookSupporterType } from '@models/book/BookSupporterType'
import { BookCategoryType } from '@models/book/BookCategoryType'
import { BookLinkType } from '@models/book/BookLinkType'
import { BookRatingType } from '@models/book/BookRatingType'
import { BookTagsType } from '@models/book/BookTagsType'
import { BookEnum } from '@interfaces/BookEnum'
import { WritingEnum } from '@interfaces/WritingEnum'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { BookBuyAllChapterPromotion } from '@models/book/BookBuyAllChapterPromotion'
import { BookReadFreePromotion } from '@models/book/BookReadFreePromotion'
import { BookBadge } from '@models/book/BookBadge'
import { StoryFieldType } from './StoryFieldType'

class WriterType {
  affiliation: AffiliationEnum

  status: WriterStatusEnum
}

class UserType {
  id: number

  @Type(() => WriterType)
  writer: WriterType

  displayName: string

  avatarImgPath: string

  profilePageSlug: string

  email: string

  isAdmin: boolean
}

export class PenNameType {
  id: number

  firstPenName: string

  facebookLink: string

  twitterLink: string

  @Type(() => UserType)
  user: UserType
}

@Exclude()
export class StoryDetailReaderType {
  @Expose() id: number

  @Expose() writer: string

  @Expose() hasSalePromotions: boolean

  @Expose()
  @Type(() => BookBuyAllChapterPromotion)
  buyAllChaptersPromotion?: BookBuyAllChapterPromotion

  @Expose()
  @Type(() => BookReadFreePromotion)
  readFreePromotion?: BookReadFreePromotion

  @Expose()
  @Type(() => BookBadge)
  bookBadges?: BookBadge[]

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType

  @Expose() coverImgPath: string

  @Expose() title: string

  @Expose() cover: string

  @Expose()
  @Type(() => BookCategoryType)
  category: BookCategoryType

  @Expose()
  @Transform(({ value }) =>
    value === WritingEnum.ORIGINAL
      ? 'ออริจินอล'
      : value === WritingEnum.FAN_FICTION
      ? 'แฟนฟิกชัน'
      : ''
  )
  writingType: string

  @Expose() isTranslated: boolean

  @Expose()
  @Type(() => BookRatingType)
  rating: BookRatingType

  @Expose() likeCount: number

  @Expose() viewCount: number

  @Expose() shelfAddedCount: number

  @Expose() lastCommentDate: string

  @Expose() commentCount: number

  @Expose() commentable: boolean

  @Expose() guestCommentable: boolean

  @Expose() isEpub: boolean

  @Expose() isPdf: boolean

  @Expose() isEnded: boolean

  @Expose() isEbook: boolean

  @Expose()
  @Type(() => BookTagsType)
  tags: BookTagsType[]

  @Expose() intro: string

  @Expose() naiinCoverImgPath: string

  @Expose() naiinTitle: string

  @Expose() naiinCover: string

  @Expose() naiinLink: string

  @Expose() updatedAt: string

  @Expose() publishedAt: string

  @Expose() createdAt: string

  @Expose() isUserHasBlockedComment: boolean

  @Expose() profilePageSlug: string

  @Expose({ name: 'bookLink' })
  @Type(() => BookLinkType)
  bookLinks: BookLinkType[]

  @Expose() bookType: BookEnum

  @Expose({ name: 'publishedCharacters' })
  @Type(() => BookCharacterType)
  characters: BookCharacterType[]

  @Expose()
  @Type(() => BookSupporterType)
  supporters: BookSupporterType[]

  @Expose()
  @Transform(({ value }) => value ?? false)
  isLiked: boolean

  @Expose()
  @Type(() => StoryFieldType)
  bookStory: StoryFieldType[]

  @Expose() dynamicLink: string

  @Expose() facebookShareImageUrl?: string

  @Expose() twitterShareImageUrl?: string

  @Expose() requireAgeVerify: boolean

  @Expose() publishedChapterCount: number

  @Expose() totalEbookSeriesPublish: number
}
